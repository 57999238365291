/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
var jQuery = $;

var tooltip = require('jquery-ui/ui/widgets/tooltip');
var moment = require('moment');
var selectize = require('@selectize/selectize');


console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

function updateQueryStringParameter(uri, key, value) {

    // when app changes, remove the build query string
    if ( key === 'app')
    {
        uri = updateQueryStringParameter ( uri , 'build' , '');
    }

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";

    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}

$(document).ready(function()
{

    const urlParams = new URLSearchParams(window.location.search);
    let start = moment(urlParams.get('from'));
    if ( ! start.isValid() ) {
        start = moment().subtract(14,'days')
    }

    let end = moment(urlParams.get('until'));

    if ( ! end.isValid() ) {
        end = moment();
    }

    // Date range picker
    $('#reportrange').daterangepicker(
        {
            minDate: moment().subtract(90, 'days'),
            maxDate: moment(),

            opens: 'left',
            dateLimit: {
                days: 90
            },
            showDropdowns: true,
            showWeekNumbers: true,
            "alwaysShowCalendars": true,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: 'YYYY-MM-DD'
            },
            startDate: start,
            endDate: end
        });

    $('#reportrange').on('apply.daterangepicker', function(ev, picker) {
        let newUrl = updateQueryStringParameter ( window.location.href ,'from' , picker.startDate.format('YYYY-MM-DD'));
            newUrl = updateQueryStringParameter ( newUrl ,'until' , picker.endDate.format('YYYY-MM-DD'));
            window.location = newUrl;
    });

    $('#select-app').selectize({
        maxItems: 1,
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        onChange: function (name) { window.location = updateQueryStringParameter ( window.location.href ,'app' , name)  ;},
        create: false
    });

    $('#select-geo').selectize({
        maxItems: 1,
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        onChange: function (name) { window.location =  updateQueryStringParameter ( window.location.href ,'geo' , name) ;},
        create: false
    });

    $('#select-network').selectize({
        maxItems: 1,
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        onChange: function (name) { window.location =  updateQueryStringParameter ( window.location.href ,'network' , name) ;},
        create: false
    });

    $('#select-format').selectize({
        maxItems: 1,
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        onChange: function (name) { window.location =  updateQueryStringParameter ( window.location.href ,'format' , name) ;},
        create: false
    });

    $('#select-build').selectize({
        maxItems: 1,
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        onChange: function (name) { window.location =  updateQueryStringParameter ( window.location.href ,'build' , name) ;},
        create: false
    });

    $('#select-experiment').selectize({
        maxItems: 1,
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        onChange: function (name) { window.location =  updateQueryStringParameter ( window.location.href ,'experiment' , name) ;},
        create: false
    });

    // Data tables
    $('#dataTable').dataTable( {
        "pageLength": 100,
        "order": [[ 0, "desc" ]]
    });

    // Data tables
    $('.myDataTable').dataTable( {
        "pageLength": 100,
        "order": [[ 0, "desc" ]]
    });

    // AdUnit Cpm FLoor Data table
    $('#dataTable-nosearch').dataTable( {
        "searching": false,
        "paging": false,
        "ordering": false
    });

    // On/Off Switches
    $('.my-switch').change(function( e ) {
        e.preventDefault();

        if ( this.id)
        {
            $.ajax({
                method: 'POST',
                url: this.id
            }).done(function (data) {
                if ( $(this).prop('checked') !== data.status) {
                    this.checked = data.status;
                }
            })
        }
    })

    // Dynamic form add/row
    $("button#form_add_row").on("click", function () {
        var newRow = $("<tr>");
        var cols = "";
        cols += '<td><input type="text" class="form-control" placeholder="Property" name="properties[]"/></td>';
        cols += '<td><input type="text" class="form-control" placeholder="Value"  name="values[]"/></td>';
        cols += '<td><button type="button" class="form-row-del btn btn-danger">Delete</button></td>';
        newRow.append(cols);
        $("table#form_dynamic_rows").append(newRow);
    });

    // Remove the closest row to the button
    $("table#form_dynamic_rows").on("click", ".form-row-del", function (event) {
        $(this).closest("tr").remove();
    });

});